import React from "react";
import { Image, useColorMode } from "@chakra-ui/react";

const Logo = (props) => {
  const { colorMode } = useColorMode();

  return (
    <Image
      alt={"logo"}
      src={`${
        colorMode === "light" ? "/logo_watchman_light.png" : "/logo_watchman_dark.png"
      }`}
      {...props}
    />
  );
};

export default Logo;
