// export function validateEmail(email, translation) {
//   const re = /^([a-z0-9._-]+)@([a-z0-9._-]{3,})\.([a-z]{2,3})$/;
//   const gmailTest = /@gmail\.([a-zA-Z]{2,3})$/;
//   const yahooTest = /@yahoo\.([a-zA-Z]{2,3})$/;
//   const hotmailTest = /@hotmail\.([a-zA-Z]{2,3})$/;

//   const lower = String(email).toLowerCase();
//   const test = !email || !re.test(lower);
//   const notValidDomain =
//     gmailTest.test(lower) || yahooTest.test(lower) || hotmailTest.test(lower);

//   let error;
//   if (test) {
//     error = translation("common:validation.email_valid");
//   } else if (notValidDomain) {
//     error = translation("common:validation.email_required");
//   }
//   return error;
// }
export function validateGeneralEmail(email, translation) {
  const re = /^([a-z0-9._-]+)@([a-z0-9._-]{3,})\.([a-z]{2,3})$/;

  const lower = String(email).toLowerCase();
  const test = !email || !re.test(lower);

  let error;
  if (test) {
    error = translation("common:validation.email_valid");
  }

  return error;
}
export function validateEmail(email, translation) {
  const re = /^([a-z0-9._-]+)@([a-z0-9._-]{3,})\.([a-z]{2,3})$/;

  const lower = String(email).toLowerCase();
  const test = !email || !re.test(lower);

  let error;
  if (test) {
    error = translation("common:validation.email_valid");
  }

  return error;
}

export function validateFirstName(name) {
  const re = /^([A-Z+]+)$/;

  const upper = String(name).toUpperCase();
  const test = !name || !re.test(upper);

  let error;
  if (test) {
    error = "Your first name must only contains alphabetical characters.";
  }
  return error;
}

export function validateLastName(name) {
  const re = /^([a-z+]+)$/;

  const lower = String(name).toLowerCase();
  const test = !name || !re.test(lower);

  let error;
  if (test) {
    error = "Your last name must only contains alphabetical characters.";
  }
  return error;
}

export function validatePhoneNumber(phone) {
  const re = /^\+[0-9]{1,3}\x20[0-9]{4,14}(?:x.+)?$/;
  const test = !phone || !re.test(phone);

  let error;
  if (test) {
    error = "Veuillez entrer un numéro de téléphone valide !";
  }
  return error;
}

export function validateAddress(address) {
  const re = /\S+/;
  const test = !address || !re.test(address);

  let error;
  if (test) {
    error = "Veuillez entrer une Adresse valide !";
  }
  return error;
}

export function validateCountry(country) {
  const re = /^([a-zA-Z]+)$/;
  const test = !country || !re.test(country);
  let error;
  if (test) {
    error = "Veuillez choisir votre pays.";
  }
  return error;
}

export const isIpAddress = (value, t) => {
  const ipRegex =
    /^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)$/;
  const test = !value || !ipRegex.test(value);
  let error;
  if (test) {
    error = t("dashboard:device.add_form.required.7");
  }
  return error;
};

export const isMacAddress = (value, t) => {
  const macRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
  const test = !value || !macRegex.test(value);
  let error;
  if (test) {
    error = t("dashboard:device.add_form.required.8");
  }
  return error;
};
