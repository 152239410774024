import fetchWithMethod from "@/src/utils/fetchWithMethod";

export async function pricing(data) {
	const url = `/api/contact/pricing/`;
	const {data: pricing, error} = await fetchWithMethod({
		method: 'POST',
		url: url,
		headers: { // no need to stringify
			"content-Type": "application/json",
		},
		data: JSON.stringify(data),
		credentials: "include",
	});

	const loading = !pricing && !error;
	const unauthorized = error && error.status === 401;

	return {
		loading,
		unauthorized,
		pricing,
		error
	};
}

export async function subscribe(data) {
	const url = `/api/contact/subscribe/`;
	const {data: subscribe, error} = await fetchWithMethod({
		method: 'POST',
		url: url,
		headers: { // no need to stringify
			"content-Type": "application/json",
		},
		data: JSON.stringify(data),
		credentials: "include",
	});

	const loading = !subscribe && !error;
	const unauthorized = error && error.status === 401;

	return {
		loading,
		unauthorized,
		subscribe,
		error
	};
}

export async function contact(data) {
	const url = `/api/contact/`;
	const {data: contact, error} = await fetchWithMethod({
		method: 'POST',
		url: url,
		headers: { // no need to stringify
			"content-Type": "application/json",
		},
		data: JSON.stringify(data),
		credentials: "include",
	});

	const loading = !contact && !error;
	const unauthorized = error && error.status === 401;

	return {
		loading,
		unauthorized,
		contact,
		error
	};
}