import React from "react";
import { Stack, useColorMode } from "@chakra-ui/react";

const LandingShell = ({ children, ...props }) => {
  const { colorMode } = useColorMode();
  return (
    <Stack
      direction={"column"}
      alignItems={"center"}
      fontWeight={500}
      fontStyle={"normal"}
      fontSize={"16px"}
      justifyContent={"space-between"}
      spacing={"136px"}
      pb={8}
      bg={colorMode === "light" ? "white" : "#021b17"}
      w={"100vw"}
      overflow={"hidden"}
      minHeight={"100vh"}
      position={"relative"}
    >
      {children}
    </Stack>
  );
};

export default LandingShell;
