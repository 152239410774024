import React from "react";
import {
  Box,
  Divider,
  Flex,
  Heading,
  Stack,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import CustomLink from "@/src/components/CustomLink";
import { Icon } from "@chakra-ui/icons";
import { FaTwitter, FaYoutube, FaLinkedin } from "react-icons/fa";
import useTranslation from "next-translate/useTranslation";

const LandingFooter = () => {
  const year = new Date().getFullYear();
  const { t } = useTranslation();
  const { colorMode } = useColorMode();
  return (
    <Stack w={"full"} px={{ base: 5, "2xl": "120px", "5xl": "450px" }}>
      <Divider my={"61px"} borderWidth={"1px"} color={"gray.500"} />
      <Flex
        justify={"space-between"}
        direction={{ base: "column", "2xl": "row" }}
        w={"full"}
      >
        <Flex
          direction={"column"}
          alignItems={"flex-start"}
          w={"full"}
          maxW={{ base: "full", "2xl": "25%" }}
          mb={3}
          mr={24}
        >
          <Heading
            as={"h1"}
            fontSize={"24px"}
            fontWeight={800}
            mb={"20px"}
            lineHeight={"36px"}
            color={colorMode === "light" ? "inherit" : "#00B295"}
          >
            Watchman
          </Heading>
          <Text
            lineHeight={"22px"}
            textAlign={"left"}
            fontSize={"16px"}
            color={colorMode === "light" ? "inherit" : "white"}
          >
            {t("home:hero_description_text")}
          </Text>
          <Flex
            flex={"1 0 auto"}
            maxW={"full"}
            justify={"space-between"}
            gap={4}
            align={"flex-start"}
            mt={8}
            mb={6}
          >
            <CustomLink
              to={"https://www.youtube.com/channel/UCq44fBvVWiOd7WVImdO6XDg"}
              isExternal
            >
              <Icon color={"red"} fontSize={30} as={FaYoutube} />
            </CustomLink>
            <CustomLink to={"#"}>
              <Icon color={"blue.700"} fontSize={30} as={FaLinkedin} />
            </CustomLink>
            <CustomLink to={"#"}>
              <Icon color={"blue.500"} fontSize={30} as={FaTwitter} />
            </CustomLink>
          </Flex>
        </Flex>
        <Flex direction={"column"} alignItems={"flex-start"} mb={"20px"}>
          <Heading
            as={"h2"}
            fontSize={"20px"}
            fontWeight={"semibold"}
            mb={3}
            lineHeight={"36px"}
            color={colorMode === "light" ? "inherit" : "#00B295"}
          >
            {t("home:footer.menu.features")}
          </Heading>
          <Flex
            direction={"column"}
            justify={"space-between"}
            gap={2}
            letterSpacing={"0.00001rem"}
            fontSize={"16px"}
            top={0}
            color={colorMode === "light" ? "inherit" : "#00B295"}
          >
            <Box>
              <CustomLink to={"/#features"}>
                {t("home:feature_alert_title")}
              </CustomLink>
            </Box>
            <Box>
              <CustomLink to={"/#features"}>
                {t("home:feature_report_title")}
              </CustomLink>
            </Box>
            {/*<Box>*/}
            {/*	<CustomLink to={"/pocs"}>{t('home:feature_poc_title')}</CustomLink>*/}
            {/*</Box>*/}
            <Box>
              <CustomLink to={"/#features"}>
                {t("home:feature_patch_title")}
              </CustomLink>
            </Box>
          </Flex>
        </Flex>
        {/*<Flex direction={"column"} alignItems={"flex-start"} mb={"20px"}>*/}
        {/*    <Heading as={"h2"} fontSize={"20px"} fontWeight={"semibold"} mb={3} lineHeight={"36px"}>Company</Heading>*/}
        {/*    <Flex direction={"column"} justify={"space-between"} gap={2} letterSpacing={"0.00001rem"} fontSize={"16px"}>*/}
        {/*        <Box>*/}
        {/*		  <CustomLink to={"/docs/learn-more"}>About Us</CustomLink>*/}
        {/*        </Box>*/}
        {/*        /!*<Box>*!/*/}
        {/*        /!*    <CustomLink to={"#career"}>Career</CustomLink>*!/*/}
        {/*        /!*</Box>*!/*/}
        {/*        /!*<Box>*!/*/}
        {/*        /!*    <CustomLink to={"#business"}>Become Investor</CustomLink>*!/*/}
        {/*        /!*</Box>*!/*/}
        {/*    </Flex>*/}
        {/*</Flex>*/}
        <Flex direction={"column"} alignItems={"flex-start"} mb={"20px"}>
          <Heading
            as={"h2"}
            fontSize={"20px"}
            fontWeight={"semibold"}
            mb={3}
            lineHeight={"36px"}
            color={colorMode === "light" ? "inherit" : "#00B295"}
          >
            {t("home:footer.menu.company")}
          </Heading>
          <Flex
            direction={"column"}
            gap={2}
            justify={"space-between"}
            letterSpacing={"0.00001rem"}
            fontSize={"16px"}
          >
            <Box>
              <CustomLink to={"/docs/terms-conditions"}>
                {t("home:footer.company.terms")}
              </CustomLink>
            </Box>
            <Box>
              <CustomLink to={"/docs/privacy-policy"}>
                {t("home:footer.company.privacy")}
              </CustomLink>
            </Box>
          </Flex>
        </Flex>
        <Flex direction={"column"} alignItems={"flex-start"} mb={"20px"}>
          <Heading
            as={"h2"}
            fontSize={"20px"}
            fontWeight={"semibold"}
            mb={3}
            lineHeight={"36px"}
            color={colorMode === "light" ? "inherit" : "#00B295"}
          >
            {t("home:footer.menu.contact")}
          </Heading>
          <Flex
            direction={"column"}
            justify={"space-between"}
            gap={2}
            letterSpacing={"0.00001rem"}
            fontSize={"16px"}
          >
            <Box>
              <CustomLink isExternal to={"https://wa.me/22991911591"}>
                WhatsApp
              </CustomLink>
            </Box>
            <Box>
              <CustomLink to={"mailto:support@watchman.bj"}>
                support@watchman.bj
              </CustomLink>
            </Box>
          </Flex>
        </Flex>
      </Flex>

      <Divider my={"24px"} color={"gray.500"} />
      <Flex justify={"center"} align={"flex-end"} pt={5} fontSize={"14px"}>
        <Text color={"copyright"}>
          Copyright &copy; {year}, Global IT Service
        </Text>
      </Flex>
    </Stack>
  );
};
export default LandingFooter;
