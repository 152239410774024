import React from "react";
import { Box, Button, Flex, IconButton, useColorMode } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

const HamburgerMenu = ({ children, ...props }) => {
  const { colorMode } = useColorMode();
  return (
    <Flex
      bg={colorMode === "light" ? "brand.400" : "#021b17"}
      w={"full"}
      py={4}
      position={"fixed"}
      zIndex={9999}
      inset={0}
      px={4}
      flexDir={"column"}
      display={props?.display}
    >
      <Flex justify={"flex-end"}>
        <IconButton
          aria-label={"Menu"}
          icon={<CloseIcon />}
          colorScheme={"brand"}
          onClick={() => props.onClose(!props?.display)}
        />
      </Flex>
      <Flex
        flexDir={"column"}
        w={"full"}
        mb={12}
        align={"center"}
        color={"white"}
        alignItems={"flex-start"}
        gap={5}
        justify={"space-between"}
      >
        {children}
      </Flex>
    </Flex>
  );
};

export default HamburgerMenu;
