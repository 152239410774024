import React, { useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Stack,
  Tooltip,
  useColorMode,
} from "@chakra-ui/react";
import CustomLink from "@/src/components/CustomLink";
import { HamburgerIcon, MoonIcon, SunIcon } from "@chakra-ui/icons";
import { useState } from "react";
import HamburgerMenu from "@/src/components/HamburgerMenu";
import { useAuth } from "@/src/contexts/AuthContext";
import useTranslation from "next-translate/useTranslation";
import Logo from "@/src/components/Logo";

const LandingHeader = () => {
  const { t } = useTranslation();
  const { isAuthenticated, checkAuthUser,isAdmin } = useAuth();
  const [displayMobile, setDisplayMobile] = useState(false);
  const [hasMounted, setHasMounted] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();

  useEffect(() => {
    checkAuthUser();
    setHasMounted(true);
  }, []);

  return (
    <Stack
      as={"header"}
      direction={"row"}
      zIndex={10}
      position={"fixed"}
      top={0}
      left={0}
      right={0}
      height={"100px"}
      w={"full"}
      alignItems={"center"}
      justifyContent={"center"}
      bg={`${colorMode === "light" ? "white" : "#021b17"}`}
    >
      <Flex
        w={"full"}
        px={{ base: "20px", "2xl": "120px", "5xl": "450px" }}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Flex flex={"1 1 auto"} alignItems={"center"}>
          <CustomLink
            fontSize={{
              base: "16px",
              sm: "16px",
              "3xl": "18px",
              "5xl": "22px",
            }}
            to={"/"}
            alignItems={"center"}
          >
            <Logo width={"215px"} height={"41px"} mb={1} />
          </CustomLink>
        </Flex>
        <Flex
          flex={"1 1 auto"}
          maxW={"750px"}
          gap={{base:"20px","3xl":"48px"}}
          justify={"flex-end"}
          alignItems={"center"}
          display={{ base: "none", "2xl": "flex" }}
        >
          <CustomLink
            fontSize={{
              base: "16px",
              sm: "16px",
              "3xl": "16px",
              "5xl": "16px",
            }}
            to={"/#features"}
          >
            {t("home:header.nav.features")}
          </CustomLink>
          <CustomLink
            fontSize={{
              base: "16px",
              sm: "16px",
              "3xl": "16px",
              "5xl": "16px",
            }}
            to={"/docs/learn-more#how-it-works"}
          >
            {t("home:header.nav.how_it_works")}
          </CustomLink>
          <CustomLink
            fontSize={{
              base: "16px",
              sm: "16px",
              "3xl": "16px",
              "5xl": "16px",
            }}
            to={"/pricing"}
          >
            {t("home:header.nav.pricing")}
          </CustomLink>
          {/*<CustomLink to={"/pocs"}>{t('home:header.nav.poc')}</CustomLink>*/}
          <CustomLink
            fontSize={{
              base: "16px",
              sm: "16px",
              "3xl": "16px",
              "5xl": "18px",
            }}
            mr={6}
            to={"/contact"}
          >
            {t("home:header.nav.contact_us")}
          </CustomLink>
          <HStack spacing={6}>
            {!isAuthenticated ? (
              <>
                <CustomLink to={"/account/login"}>
                  <Button
                    lineHeight={{ base: "24px", "3xl": "36px", "5xl": "38px" }}
                    fontSize={{
                      base: "16px",
                      sm: "16px",
                      "3xl": "16px",
                      "5xl": "16px",
                    }}
                    letterSpacing={"0.065em"}
                    fontWeight={400}
                    variant={"outline2"}
                    colorScheme={"brand"}
                    bg={colorMode === "light" ? "transparent" : "#314542"}
                  >
                    {t("home:header.nav.login")}
                  </Button>
                </CustomLink>
                <CustomLink to={"/account/choose"}>
                  <Button
                    lineHeight={{ base: "24px", "3xl": "36px", "5xl": "38px" }}
                    fontSize={{
                      base: "16px",
                      sm: "16px",
                      "3xl": "16px",
                      "5xl": "16px",
                    }}
                    letterSpacing={"0.065em"}
                    fontWeight={400}
                    variant={"solid"}
                    colorScheme={"brand"}
                  >
                    {t("home:header.nav.register")}
                  </Button>
                </CustomLink>
                <Tooltip
                  placement="bottom"
                  label={`${
                    colorMode === "light"
                      ? t("home:header.nav.dark_mode")
                      : t("home:header.nav.light_mode")
                  }`}
                >
                  <Button
                    _hover={{ background: "#E2E8F0" }}
                    onClick={toggleColorMode}
                  >
                    {colorMode === "light" ? (
                      <MoonIcon />
                    ) : (
                      <SunIcon color="icon" />
                    )}
                  </Button>
                </Tooltip>
              </>
            ) : (
              <>
                <CustomLink
                  fontSize={{
                    base: "16px",
                    "2xl": "16px",
                    "3xl": "16px",
                    "5xl": "18px",
                  }}
                  ml={"85px"}
                  to={isAdmin ? "/app/admin" : "/app/dashboard"}
                  color={"brand.600"}
                >
                  {t("home:header.nav.goto_dashboard")}
                </CustomLink>
                <Tooltip
                  placement="bottom"
                  label={`${
                    colorMode === "light"
                      ? t("home:header.nav.dark_mode")
                      : t("home:header.nav.light_mode")
                  }`}
                >
                  <Button
                    _hover={{ background: "#E2E8F0" }}
                    onClick={toggleColorMode}
                  >
                    {colorMode === "light" ? (
                      <MoonIcon />
                    ) : (
                      <SunIcon color="icon" />
                    )}
                  </Button>
                </Tooltip>
              </>
            )}
          </HStack>
        </Flex>
        <Flex
          flex={"1 0 auto"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          display={{ base: "flex", "2xl": "none" }}
        >
          <IconButton
            aria-label={"Menu"}
            fontSize={32}
            icon={<HamburgerIcon />}
            color={"brand.500"}
            backgroundColor={"transparent"}
            onClick={() => setDisplayMobile(!displayMobile)}
          />
          {displayMobile && (
            <HamburgerMenu display={displayMobile} onClose={setDisplayMobile}>
              <Box>
                <CustomLink to={"/"}>{t("home:header.nav.home")}</CustomLink>
              </Box>
              <Box>
                <CustomLink to={"/#features"}>
                  {t("home:header.nav.features")}
                </CustomLink>
              </Box>
              <Box>
                <CustomLink to={"/docs/learn-more#how-it-works"}>
                  {t("home:header.nav.how_it_works")}
                </CustomLink>
              </Box>
              <Box>
                <CustomLink to={"/pricing"}>
                  {t("home:header.nav.pricing")}
                </CustomLink>
              </Box>
              {/*<Box>*/}
              {/*	<CustomLink to={"/pocs"}>{t('home:header.nav.poc')}</CustomLink>*/}
              {/*</Box>*/}
              <Box>
                <CustomLink to={"/contact"}>
                  {t("home:header.nav.contact_us")}
                </CustomLink>
              </Box>
              {!isAuthenticated ? (
                <>
                  <Box w={"full"}>
                    <CustomLink to={"/account/login"}>
                      <Button
                        lineHeight={"24px"}
                        fontSize={"16px"}
                        letterSpacing={"0.065em"}
                        fontWeight={400}
                        w={"full"}
                        colorScheme={"brand"}
                      >
                        {t("home:header.nav.login")}
                      </Button>
                    </CustomLink>
                  </Box>
                  <Box w={"full"}>
                    <CustomLink to={"/account/choose"}>
                      <Button
                        lineHeight={"24px"}
                        fontSize={"16px"}
                        letterSpacing={"0.065em"}
                        fontWeight={400}
                        w={"full"}
                        colorScheme={"brand"}
                        color={"white"}
                        variant={"outline2"}
                      >
                        {t("home:header.nav.register")}
                      </Button>
                    </CustomLink>
                  </Box>
                  <Tooltip
                    placement="bottom"
                    label={`${
                      colorMode === "light"
                        ? t("home:header.nav.dark_mode")
                        : t("home:header.nav.light_mode")
                    }`}
                  >
                    <Button
                      w={"full"}
                      _hover={{ background: "#E2E8F0" }}
                      onClick={toggleColorMode}
                    >
                      {colorMode === "light" ? (
                        <MoonIcon />
                      ) : (
                        <SunIcon color="icon" />
                      )}
                    </Button>
                  </Tooltip>
                </>
              ) : (
                <>
                  <Box w={"full"}>
                    <CustomLink to={isAdmin ? "/app/admin" : "/app/dashboard"}>
                      <Button
                        lineHeight={"24px"}
                        fontSize={"16px"}
                        letterSpacing={"0.065em"}
                        fontWeight={400}
                        w={"full"}
                        colorScheme={"brand"}
                      >
                        {t("home:header.nav.goto_dashboard")}
                      </Button>
                    </CustomLink>
                  </Box>
                  <Tooltip
                    placement="bottom"
                    label={`${
                      colorMode === "light"
                        ? t("home:header.nav.dark_mode")
                        : t("home:header.nav.light_mode")
                    }`}
                  >
                    <Button
                      w={"full"}
                      _hover={{ background: "#E2E8F0" }}
                      onClick={toggleColorMode}
                    >
                      {colorMode === "light" ? (
                        <MoonIcon />
                      ) : (
                        <SunIcon color="icon" />
                      )}
                    </Button>
                  </Tooltip>
                </>
              )}
            </HamburgerMenu>
          )}
        </Flex>
      </Flex>
    </Stack>
  );
};

export default LandingHeader;
