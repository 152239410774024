import React from "react";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import { validateGeneralEmail } from "@/src/utils/validate-functions";
import { useForm } from "react-hook-form";
import { pricing, subscribe } from "@/services/contact";
import useTranslation from "next-translate/useTranslation";

const NewsLetter = () => {
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "all" });
  const { colorMode } = useColorMode();

  async function onSubmit(data) {
    setLoading(true);
    const resp = await subscribe(data);
    if (resp?.subscribe) {
      setLoading(false);
      toast({
        position: "top-right",
        title: t("dashboard:notification.success"),
        description: resp?.subscribe?.detail,
        status: "success",
        duration: 8000,
        isClosable: true,
      });
setEmail('');
    } else {
      setLoading(false);
      toast({
        position: "top-right",
        title: t("dashboard:notification.error"),
        description: t("dashboard:notification.error_desc"),
        status: "error",
        duration: 8000,
        isClosable: true,
      });
      setLoading(false);
    }

  }
  return (
    <Stack
      as={"section"}
      w={"full"}
      px={{ base: 3, "2xl": "120px", "5xl": "450px" }}
      pt={{ base: 8, sm: 0 }}
    >
      <Flex
        direction={{ base: "column", sm: "row" }}
        align={"flex-start"}
        borderRadius={"25px"}
        justify={"space-around"}
        py={{ base: "34px", "2xl": "50px" }}
        px={{ base: "24px", sm: "0px" }}
        backgroundColor={"news_bg"}
        position={"relative"}
      >
        <Flex
          bottom={0}
          top={0}
          left={{ base: "50%", sm: "60%" }}
          zIndex={0}
          maxW={"50%"}
          flex={"1 1 auto"}
          backgroundColor={"brand.600"}
          position={"absolute"}
          right={0}
          borderRadius={"80px 25px 25px 100px / 80px 25px 25px 120px "}
          align={"center"}
        />
        <Flex
          flexDir={"column"}
          zIndex={5}
          flex={{ sm: "0 1 414px" }}
          w={"full"}
        >
          <Heading
            as={"h1"}
            size={"md"}
            color={colorMode === "light" ? "inherit" : "#00B295"}
          >
            {t("home:news_title")}
          </Heading>
          <Text mt={3} color={colorMode === "light" ? "inherit" : "white"}>
            {t("home:news_description")}
          </Text>
        </Flex>
        <Flex
          as={"form"}
          onSubmit={handleSubmit((data) => onSubmit(data))}
          flexDir={"row"}
          w={"full"}
          h={"full"}
          transform={"auto"}
          alignItems={"center"}
          zIndex={5}
          flex={{ sm: "0 1 40%" }}
        >
          <InputGroup mt={5} transform={"auto"}>
            <FormControl isInvalid={errors.email}>
              <Input
                borderRadius={"full"}
                w={"full"}
                placeholder={t("home:news_input")}
                id="email"
                name={"email"}
                type={"email"}
                value={email}
                {...register("email", {
                  required: t("home:news_required_email"),
                  validate: (email) => {
                    return validateGeneralEmail(email, t);
                  },
                  onChange: (event)=>setEmail(event.target.value)
                  
                })}
              />
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
            <InputRightElement minW={"125px"} mt={1} mr={1}>
              <Button
                borderRadius={"full"}
                w={"full"}
                type={"submit"}
                colorScheme={"brand"}
              >
                {t("home:news_button")}
              </Button>
            </InputRightElement>
          </InputGroup>
        </Flex>
      </Flex>
    </Stack>
  );
};

export default NewsLetter;
