import React from 'react';
import {NextSeo} from 'next-seo';

const Page = ({ name, path, children }) => {
	const title = `Watchman – ${name}`;
	const url = `https://watchman.bj${path}`;

	return (
		<>
			<NextSeo
				title={title}
				canonical={url}
				openGraph={{
					url,
					title
				}}
			/>
			{children}
		</>
	);
};

export default Page;
