import React from "react";
import { Link, useColorMode } from "@chakra-ui/react";
import NextLink from "next/link";

const CustomLink = ({ to, children, fontWeight, fontSize, ...props }) => {
  const { colorMode } = useColorMode();
  return (
    <NextLink href={to} passHref>
      <Link
        {...props}
        fontWeight={fontWeight ? fontWeight : 400}
        lineHeight={"24px"}
        fontSize={fontSize ? fontSize : "16px"}
        whiteSpace={"nowrap"}
        _hover={{
          textDecoration: "none",
          color: colorMode === "light" ? "brand.700" : "brand.300",
        }}
        colorScheme={"brand"}
        _focus={{
          outline: "none",
          color: colorMode === "light" ? "brand.500" : "brand.300",
        }}
        color={`${colorMode === "light" ? "#000" : "brand.700"}`}
      >
        {children}
      </Link>
    </NextLink>
  );
};

export default CustomLink;
